import request from '~/common/utils/request'

export function getLinkDetect3G () {
  return request({
    url: '/price/getLinkDetect3G',
    method: 'get'
  })
}

export function getLinkRegister (params) {
  return request({
    url: '/price/getLinkRegister',
    method: 'get',
    params
  })
}

export function login3G (data) {
  return request({
    url: '/price/login3G',
    method: 'post',
    data
  })
}
